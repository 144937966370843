import React from 'react'
import StudentDetails from '../../pages/LogInPage/StudentDetails';

const AddStudentDetails = () => {
  return (
    <div className="px-3 py-6 gap-9 flex flex-col items-center">
      <h1 className="font-gully text-[40px] font-bold text-center text-appPrimaryColor">
        Add student details
      </h1>
      <StudentDetails />
    </div>
  );
}

export default AddStudentDetails
