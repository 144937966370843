import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import {
  resetUserDataForm,
  setCurrentStep,
  setUserData,
  setUserDataErrors,
} from "../../redux/Slices/profileSetupSlice";
import {
  formatPhoneNumberToInternational,
  useEmailValidation,
  usePasswordValidation,
  usePhoneValidation,
} from "../../utils";
import {
  useCountyGetAllQuery,
  useCreateStudentMutation,
  useGetCountySchoolByIdQuery,
  useGetUserDataByIdQuery,
  useLazyGetCountySchoolByIdQuery,
  useLazyIsUserExistsQuery,
  useParentProfileMutation,
  useProfileVerificationMutation,
  useUpdateUserMutation,
} from "../../redux/API/ProfileSetup/profileSetupApi";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getItem, setItem } from "../../utils/token";
import { GrCircleAlert } from "react-icons/gr";
import { jwtDecode } from "jwt-decode";
import LinkExpired from "../../components/ReUsableComponents/LinkExpired";
import StudentDetailsForm from "./StudentDetailsForm";

export const getSelectedName = (data: any, selected: any, name: string) => {
  const selectedItem = data?.find((item: any) => item.id === selected);
  if (name === "county") {
    return selectedItem ? selectedItem.countyName : "";
  }
  if (name === "school") {
    return selectedItem ? selectedItem.schoolName : "";
  }
  return "";
};

const StudentDetails = ({
  isStudentRoute = false,
}: {
  isStudentRoute?: boolean;
}) => {
  const { currentStep, userData, selectedRole } = useSelector(
    (state: RootState) => state.root.profileSetupSlice
  );
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isCountyDropdownOpen, setIsCountyDropdownOpen] = useState(false);
  const { validateEmail } = useEmailValidation();
  const { validatePhone } = usePhoneValidation();
  const [parentProfile] = useParentProfileMutation();
  const [createStudent] = useCreateStudentMutation();
  const [updateUser] = useUpdateUserMutation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [profileVerification] = useProfileVerificationMutation();
  const { setPasswordError, passwordError, validatePassword } =
    usePasswordValidation();

  const id = searchParams.get("id2");
  const decodedId = atob(id || "");

  useCountyGetAllQuery({}, { refetchOnMountOrArgChange: true });
  const [getCountySchoolById] = useLazyGetCountySchoolByIdQuery();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useGetUserDataByIdQuery(decodedId, {
    refetchOnMountOrArgChange: true,
    skip: !isStudentRoute || !decodedId,
  });

  const {
    studentFirstName,
    studentLastName,
    studentUserName,
    studentPassword,
    studentPhoneNumber,
    county,
    school,
    parentFirstName,
    parentPhoneNumber,
  } = userData;
  useGetCountySchoolByIdQuery(county, { skip: !county });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setUserData({ [name]: value.replace(/\s+/g, " ").trimStart() }));
    // Clear the error when typing
    dispatch(setUserDataErrors({ [name]: false }));
    setError("");
    setPasswordError("");
  };
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isUserExists] = useLazyIsUserExistsQuery();

  const handleApiError = (error: any) => {
    if (error.data) {
      toast.error(error.data.message);
    }
    console.error(error, "API Error");
  };
  const handleParentProfile = async () => {
    const isEmailExists = await checkIsEmailExists(studentUserName);
    if (isStudentRoute || isEmailExists) {
      dispatch(setUserDataErrors({ studentUserName: true }));
      return;
    }
    try {
      setLoading(true);
      const userEmail = getItem("userEmail");
      const response = await parentProfile({
        ...userData,
        isParent: true,
        parentUserName: userEmail?.trim()?.toLocaleLowerCase(),
        studentPhoneNumber:
          formatPhoneNumberToInternational(studentPhoneNumber),
        parentPhoneNumber: formatPhoneNumberToInternational(parentPhoneNumber),
      }).unwrap();
      if (response.status === "success") {
        setItem("token", response?.data);
        const decoded: any = jwtDecode(response?.data);
        setItem("userName", decoded?.firstName);
        setItem("userRole", decoded?.role ?? null);
        setItem("userEmail", decoded?.userEmail);
      }
      const res: any = await createStudent({
        ...userData,
        isParent: true,
        parentUserName: userEmail?.trim()?.toLowerCase(),
        parentPhoneNumber: formatPhoneNumberToInternational(parentPhoneNumber),
        studentPhoneNumber:
          formatPhoneNumberToInternational(studentPhoneNumber),
      });

      if (res?.data) {
        setItem("userName", parentFirstName);
        toast.success(res.data);
        navigate("/parentdashboard");
        setLoading(false);
        resetUserDataForm();
      }
    } catch (error: any) {
      handleApiError(error);
    }
  };

  const validatePasswordMatch = () => {
    if (studentPassword !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }
    setError("");
    return true;
  };

  const checkIsEmailExists = async (value: string) => {
    try {
      const payload = {
        email: value,
        role: selectedRole === "parent" ? "STUDENT" : "PARENT",
      };
      await isUserExists(payload).unwrap();
      dispatch(setUserDataErrors({ studentUserName: false }));
      return false;
    } catch (error: any) {
      console.log(error, "error789");
      if (error?.originalStatus === 200) {
        dispatch(setUserDataErrors({ studentUserName: false }));
      } else {
        setError("User already exists with given email");
        dispatch(setUserDataErrors({ studentUserName: true }));
      }
      if (error?.originalStatus === 200) {
        return false;
      } else {
        setError("User already exists with given email");
        return true;
      }
    }
  };

  const handleSubmitClick = async () => {
    const newErrors = {
      studentFirstName: !studentFirstName.trim(),
      studentLastName: !studentLastName.trim(),
      studentUserName: !isStudentRoute && !studentUserName.trim(),
      county: !county,
      school: !school,
      studentPassword: isStudentRoute && !studentPassword.trim(),
      studentPhoneNumber: false,
    };
    const isEmailValid = validateEmail(studentUserName);
    const isPhoneValid = validatePhone(studentPhoneNumber);
    const isPasswordvalid = validatePassword(studentPassword);

    const isPasswordsMatch = validatePasswordMatch();

    if (!isEmailValid) {
      newErrors.studentUserName = true;
    }

    if (!isPhoneValid) {
      newErrors.studentPhoneNumber = true;
    }

    if (isStudentRoute && (!isPasswordvalid || !isPasswordsMatch)) {
      newErrors.studentPassword = true;
    }

    dispatch(setUserDataErrors({ ...newErrors }));
    const hasErrors = Object.values(newErrors).some(Boolean);

    if (!hasErrors && isEmailValid && isPhoneValid) {
      try {
        if (isStudentRoute && isPasswordsMatch && isPasswordvalid) {
          setLoading(true);
          const payload = {
            ...userData,
            isParent: false,
            studentPhoneNumber:
              formatPhoneNumberToInternational(studentPhoneNumber),
          };
          const params = {
            userId: decodedId,
            verificationType: "INVITATION",
          };
          await profileVerification(params).unwrap();
          const res = await updateUser({
            userId: decodedId,
            payload,
          }).unwrap();
          if (res.status === "success") {
            setLoading(false);
            navigate("/login");
          }
        } else {
          handleParentProfile();
        }
      } catch (error: any) {
        setLoading(false);
        handleApiError(error);
      }
    }
  };

  const handleNextClick = () => {
    const newErrors = {
      studentFirstName: !studentFirstName.trim(),
      studentLastName: !studentLastName.trim(),
      county: !county,
      school: !school,
    };
    dispatch(setUserDataErrors({ ...newErrors }));
    const isEmailValid = validateEmail(studentUserName);
    const isPhoneValid = validatePhone(studentPhoneNumber);

    if (isStudentRoute) {
      dispatch(setUserDataErrors({ studentPassword: true }));
    }

    if (!isPhoneValid) {
      dispatch(setUserDataErrors({ studentPhoneNumber: true }));
    }

    const hasErrors = Object.values(newErrors).some(Boolean);

    if (!hasErrors && isEmailValid && isPhoneValid) {
      dispatch(setCurrentStep("studentParentDetails"));
    }
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    let formattedNumber = input;

    if (input.length >= 3) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    }
    if (input.length >= 6) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(
        3,
        6
      )}-${input.slice(6)}`;
    }
    if (input.length > 10) {
      formattedNumber = formattedNumber.slice(0, 14); // Limit to 10 digits
    }

    dispatch(setUserData({ studentPhoneNumber: formattedNumber }));
    dispatch(setUserDataErrors({ studentPhoneNumber: false }));
  };
  const handleDropdownChange = (val: any, name: string) => {
    if (name === "county") {
      if (val?.id === "other") {
        dispatch(setUserData({ school: '' }));
      }
      getCountySchoolById(val?.id);
      dispatch(setUserData({ [name]: val?.id }));
    } else {
      dispatch(setUserData({ [name]: val?.id }));
    }
    dispatch(setUserDataErrors({ [name]: false }));
    setIsCountyDropdownOpen(false);
    setOpen(false);
  };

  const handleActionButton = () => {
    const handleSubmit = {
      buttonText: "Submit",
      action: handleSubmitClick,
    };

    const handleNext = {
      buttonText: "Next",
      action: handleNextClick,
    };

    return isStudentRoute || currentStep === "parentStudentDetails"
      ? handleSubmit
      : handleNext;
  };

  return (
    <>
      {userData.isExpired ? (
        <LinkExpired />
      ) : (
        <div
          className={`font-gully ${
            isStudentRoute
              ? "flex h-screen justify-center items-center flex-col"
              : ""
          }`}
        >
          {(currentStep === "parentStudentDetails" || isStudentRoute) && (
            <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer mb-9">
              Enter student’s detail
            </p>
          )}
          {(error || passwordError) && (
            <div className="min-w-[446px]  max-w-[520px] flex items-center gap-x-1  py-3 px-5 mb-9 border border-[#d00000] rounded-xl">
              <div>
                <GrCircleAlert size={24} className="text-[#D00000] mr-[10px]" />
              </div>
              <p className="text-[#D00000] text-base font-semibold">
                {passwordError || error}
              </p>
            </div>
          )}
          <StudentDetailsForm
            checkIsEmailExists={checkIsEmailExists}
            confirmPassword={confirmPassword}
            handleActionButton={handleActionButton}
            handleChange={handleChange}
            handleDropdownChange={handleDropdownChange}
            handlePhoneChange={handlePhoneChange}
            isStudentRoute={isStudentRoute}
            loading={loading}
            setConfirmPassword={setConfirmPassword}
            setError={setError}
            setIsCountyDropdownOpen={setIsCountyDropdownOpen}
            isCountyDropdownOpen={isCountyDropdownOpen}
            open={open}
            setOpen={setOpen}
          />
        </div>
      )}
    </>
  );
};

export default StudentDetails;
