import { Switch } from "@headlessui/react";
import { ChartOptions } from "chart.js";
import { useState, useEffect } from "react";
import { Line } from "react-chartjs-2";
import { formatDateForWidgets } from "../../../utils";
import { ScoreGraphData, ScoreWidgetProps, TestResult } from "./dataTypes";



const ScoreWidget = ({ overallResult }: ScoreWidgetProps) => {
    const [enabled, setEnabled] = useState(true);
    const [chartData, setChartData] = useState<ScoreGraphData>({
      labels: [],
      datasets: [],
    });
  
    const convertData = (data: TestResult[], chartTitle: string) => {
      let requiredData: ScoreGraphData = {
        labels: [],
        datasets: [],
      };
  
      if (!data) {
        return requiredData;
      }
  
      data.forEach((item) => {
        const formattedDate = formatDateForWidgets(item.testtakendate);
        requiredData.labels.push(formattedDate);
      });
  
      let readingAndWritingData;
      let mathematicsData;
      let totalScoreData;
  
      if (chartTitle === "RawScore") {
        readingAndWritingData = data.map(
          (item) => item.scores.readingAndWritingRawScore
        );
        mathematicsData = data.map((item) => item.scores.mathematicsRawScore);
        totalScoreData = data.map(
          (item) =>
            item.scores.readingAndWritingRawScore +
            item.scores.mathematicsRawScore
        );
      } else {
        readingAndWritingData = data.map(
          (item) => item.scores.readingAndWritingScaledScore
        );
        mathematicsData = data.map((item) => item.scores.mathematicsScaledScore);
        totalScoreData = data.map((item) => item.scores.totalScaledScore);
      }
  
      requiredData.datasets = [
        {
          label: "R&W",
          data: readingAndWritingData,
          borderColor: "#2D728F",
          borderWidth: 2,
          pointBackgroundColor: "#2D728F",
          tension: 0.0,
          fill: false,
        },
        {
          label: "Math",
          data: mathematicsData,
          borderColor: "#E01A4F",
          borderWidth: 2,
          pointBackgroundColor: "#E01A4F",
          tension: 0.0,
          fill: false,
        },
        {
          label: "Total Score",
          data: totalScoreData,
          borderColor: "#313715",
          borderWidth: 2,
          pointBackgroundColor: "#313715",
          tension: 0.0,
          fill: false,
        },
      ];
  
      return requiredData;
    };
    const options: ChartOptions<"line"> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          labels: {
            usePointStyle: false,
            font: {
              size: 12,
              family: "Gully",
            },
            boxWidth: 14,
            boxHeight: 14,
            textAlign: "center",
            generateLabels: (chart) => {
              return chart.data.datasets.map((dataset, index) => ({
                text: dataset.label || "Unnamed Dataset",
                fillStyle: dataset.borderColor as string,
                hidden: !chart.isDatasetVisible(index),
                strokeStyle: dataset.borderColor as string,
                lineWidth: 1,
                datasetIndex: index,
              }));
            },
          },
        },
        tooltip: {
          callbacks: {
            title: (tooltipItem) => {
              const index = tooltipItem[0].dataIndex;
              const testName = overallResult[index]?.name;
              return `${testName}`;
            },
            label: (tooltipItem) => {
              return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          offset: true,
          ticks: {
            color: "#000",
            align: "center",
            font:{
              size: 10,
              weight: "bold",
              family: "Gully",
            }
          },
          grid: {
            drawTicks: true,
            display: false,
          },
          title: {
            display: true,
            text: "TEST DATES",
            color: "#000",
            font: {
              size: 12,
              weight: "bold",
              family: "Gully",
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: true,
          },
          title: {
            display: true,
            text: enabled ? "SCALED SCORE" : "RAW SCORE",
            color: "#000",
            font: {
              size: 12,
              weight: "bold",
              family: "Gully",
            },
          },
          ticks:{
            font:{
              size: 10,
              weight: "bold",
              family: "Gully",
            }
          }
        },
      },
    };
  
    useEffect(() => {
      if (enabled) {
        setChartData(convertData(overallResult, "ScaledScore"));
      } else {
        setChartData(convertData(overallResult, "RawScore"));
      }
    }, [enabled]);
  
    useEffect(() => {
      setChartData(convertData(overallResult, "ScaledScore"));
    }, [overallResult]);
  
    return (
      <div
        className="w-full flex flex-col py-3 px-6 justify-between rounded-xl"
        style={{ boxShadow: "0px 0px 10px 3px rgba(32, 32, 32, 0.10)" }}
      >
        <p className="text-center font-gully text-[21px] font-medium">
          Test score progress
        </p>
        <div>
          <div className="w-full flex justify-center items-center gap-6">
            <p className="font-gully text-xs font-semibold text-appPrimaryColor">
              RAW SCORE
            </p>
            <Switch
              checked={enabled}
              onChange={() => setEnabled(!enabled)}
              className={`relative flex h-7 w-14 cursor-pointer rounded-full p-1 transition-colors duration-200 ease-in-out focus:outline-none ${
                enabled ? "bg-[#283044]" : "bg-appPrimaryColor"
              }`}
            >
              <span
                aria-hidden="true"
                className={`inline-block size-5 rounded-full bg-white shadow-lg transition-transform duration-200 ease-in-out ${
                  enabled ? "translate-x-7" : "translate-x-0"
                }`}
              />
            </Switch>
            <p className="font-gully text-xs font-semibold text-[#283044]">
              SCALED SCORE
            </p>
          </div>
  
          <div className="w-full min-h-[300px] max-h-[400px]">
            <Line data={chartData} options={options} />
          </div>
        </div>
      </div>
    );
  };

export default ScoreWidget;