import { HiOutlineHome } from "react-icons/hi2";
import { ErrorImage } from "../../pages/LogInPage/EmailVerficationPromptConfig";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { useNavigate } from "react-router-dom";

const LinkExpired = ({ redirectTo }: { redirectTo?: string }) => {
  const navigate = useNavigate();
  const handleReturntoHome = () => {
    if (redirectTo) {
      navigate(redirectTo);
    } else {
      navigate("/login");
    }
  };
  return (
    <div className="flex flex-col items-center justify-center font-gully">
      <div className="flex flex-col justify-center items-center px-10">
        <p className="font-bold text-[#327AEC] mt-[144px] mb-9 text-center text-[55px] font-pretendard cursor-pointer">
          S A T
        </p>
        <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer mb-9">
          Seems like you got lost...
        </p>
        <div>
          <ErrorImage />{" "}
        </div>
        <AuthButton
          text="Return To Home"
          icon={<HiOutlineHome size={24} />}
          iconPosition="left"
          onClick={handleReturntoHome}
          loader={false}
          disabled={false}
        />
      </div>
    </div>
  );
};

export default LinkExpired;
