import { Menu, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import { OptionType } from "./TestHeader";

export default function Dropdown({
  options = [],
  buttonLabel = "Options",
}: {
  options: OptionType[];
  buttonLabel: string | ReactNode;
}) {
  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md px-4 text-sm font-medium text-black focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
            {buttonLabel}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            style={{ boxShadow: "0px 4px 10px 0px #00000040" }}
            className="absolute right-0 mt-9 w-72 origin-top-right rounded-lg bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
          >
            {options.map((option, index) => (
              <div key={index} className="px-5 py-4 hover:bg-[#F0F0F0]">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={option.onClick}
                      className={`${
                        active ? "underline" : "text-gray-900"
                      } group flex w-full items-center rounded-md font-semibold px-2 py-2 text-base`}
                    >
                      {option.label}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}
