import AuthButton from "./LoginButton";

export type EmailVerificationPromptType = {
  title: string;
  description: string;
  children?: React.ReactNode;
  btnLabel: string;
  btnDisable: boolean;
  onClickHandler: () => void;
  icon: React.ReactNode;
  additionalContent?: React.ReactNode;
  img?: React.ReactNode;
};

const EmailVerificationPrompt = ({
  title,
  description,
  children,
  btnLabel,
  btnDisable,
  onClickHandler,
  icon,
  additionalContent,
  img,
}: EmailVerificationPromptType) => {
  return (
    <div className="flex flex-col items-center justify-center font-gully">
      <div className="flex flex-col justify-center items-center px-10">
        <p className="font-bold text-[#327AEC] mt-[144px] mb-9 text-center text-[55px] font-pretendard cursor-pointer">
          S A T
        </p>
        <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer mb-9">
          {title}
        </p>
        {img && <div>{img}</div>}
        <p className="mb-9 leading-[21.6px] text-center text-lg w-[100%] text-[#272727]">
          {description} 
        </p>
        {children}
        {btnLabel.trim() !== "" && (
          <AuthButton
            text={btnLabel}
            icon={icon}
            iconPosition="left"
            onClick={onClickHandler}
            loader={false}
            disabled={btnDisable}
          />
        )}
      </div>
    </div>
  );
};

export default EmailVerificationPrompt;
