import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/Store/store";
import {
  setUserDataErrors,
  setUserData,
  resetUserDataForm,
} from "../../redux/Slices/profileSetupSlice";
import {
  formatPhoneNumberToInternational,
  useEmailValidation,
  usePasswordValidation,
  usePhoneValidation,
} from "../../utils";
import {
  useCreateParentMutation,
  useGetUserDataByIdQuery,
  useLazyAssociatedetailsQuery,
  useLazyIsEmailExistsQuery,
  useProfileVerificationMutation,
  useStudentProfileMutation,
  useUpdateUserMutation,
} from "../../redux/API/ProfileSetup/profileSetupApi";
import { getItem, setItem } from "../../utils/token";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { GrCircleAlert } from "react-icons/gr";
import { jwtDecode } from "jwt-decode";
import LinkExpired from "../../components/ReUsableComponents/LinkExpired";
import ParentDetailsForm from "./ParentDetailsForm";
import { closeDialog } from "../../redux/Slices/dialogSlice";

const ParentDetails = ({
  isParentRoute = false,
  isDashboard = false,
}: {
  isParentRoute?: boolean;
  isDashboard?: boolean;
}) => {
  const dispatch = useDispatch();
  const { currentStep, userData } = useSelector(
    (state: RootState) => state.root.profileSetupSlice
  );

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id1");
  const decodedId = id ? atob(id) : null;
  const [associatedetails] = useLazyAssociatedetailsQuery();

  const { validateEmail } = useEmailValidation();
  const { validatePhone } = usePhoneValidation();
  // Regex to match US phone number format: (123) 456-7890
  const [studentProfile] = useStudentProfileMutation();
  const [createParent] = useCreateParentMutation();
  const [updateUser] = useUpdateUserMutation();
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileVerification] = useProfileVerificationMutation();
  const { setPasswordError, passwordError, validatePassword } =
    usePasswordValidation();
  const [isEmailExists] = useLazyIsEmailExistsQuery();

  const {
    parentFirstName,
    parentLastName,
    parentUserName,
    parentPhoneNumber,
    parentPassword,
    studentFirstName,
    studentPhoneNumber,
  } = userData;
  useGetUserDataByIdQuery(decodedId, {
    refetchOnMountOrArgChange: true,
    skip: !isParentRoute || !decodedId,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(setUserData({ [name]: value.replace(/\s+/g, " ").trimStart() }));
    dispatch(setUserDataErrors({ [name]: false }));
    setError("");
    setPasswordError("");
  };
  const navigate = useNavigate();
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    let formattedNumber = input;

    // Format input as (XXX) XXX-XXXX
    if (input.length >= 3) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(3)}`;
    }
    if (input.length >= 6) {
      formattedNumber = `(${input.slice(0, 3)}) ${input.slice(
        3,
        6
      )}-${input.slice(6)}`;
    }
    if (input.length > 10) {
      formattedNumber = formattedNumber.slice(0, 14); // Limit to 10 digits
    }
    dispatch(setUserData({ parentPhoneNumber: formattedNumber }));
    dispatch(setUserDataErrors({ parentPhoneNumber: false }));
  };

  const validatePasswordMatch = () => {
    if (parentPassword !== confirmPassword) {
      setError("Passwords do not match");
      return false;
    }
    setError("");
    return true;
  };

  const handleFinishClick = async () => {
    const newErrors = {
      parentFirstName: false,
      parentLastName: false,
      parentUserName: false,
      parentPhoneNumber: false,
      parentPassword: false,
    };
    if (isParentRoute || isDashboard) {
      newErrors.parentFirstName = !parentFirstName;
      newErrors.parentLastName = !parentLastName;
      newErrors.parentUserName = !parentUserName;
      newErrors.parentPassword = isDashboard ? false : !parentPassword;
    }

    const isEmailValid = validateEmail(parentUserName);
    const isPhoneValid = validatePhone(parentPhoneNumber);
    const isPasswordvalid = validatePassword(parentPassword);
    const isPasswordsMatch = validatePasswordMatch();

    if (!isEmailValid) {
      newErrors.parentUserName = true;
    }

    if (!isPhoneValid) {
      newErrors.parentPhoneNumber = true;
    }

    if (isParentRoute && (!isPasswordvalid || !isPasswordsMatch)) {
      newErrors.parentPassword = true;
    }

    dispatch(setUserDataErrors(newErrors));

    if (
      !isEmailValid ||
      !isPhoneValid ||
      Object.values(newErrors).some(Boolean)
    ) {
      console.log("Validation failed.");
      return;
    }

    try {
      if (isParentRoute && isPasswordsMatch && isPasswordvalid) {
        setLoading(true);
        const payload = {
          ...userData,
          isParent: true,
          parentPhoneNumber:
            formatPhoneNumberToInternational(parentPhoneNumber),
        };
        const params = {
          userId: decodedId,
          verificationType: "INVITATION",
        };
        await profileVerification(params).unwrap();
        const res = await updateUser({
          userId: decodedId,
          payload,
        }).unwrap();
        if (res.status === "success") {
          navigate("/login");
        }
      } else {
        if (isParentRoute) return;
        setLoading(true);
        const userName = getItem("userEmail");
        if (!isDashboard) {
          const response = await studentProfile({
            ...userData,
            isParent: false,
            studentUserName: userName?.trim()?.toLowerCase(),
            parentPhoneNumber:
              formatPhoneNumberToInternational(parentPhoneNumber),
            studentPhoneNumber:
              formatPhoneNumberToInternational(studentPhoneNumber),
          }).unwrap();
          if (response.status === "success") {
            setItem("token", response?.data);
            const decoded: any = jwtDecode(response?.data);
            setItem("userName", decoded?.firstName);
            setItem("userRole", decoded?.role ?? null);
            setItem("userEmail", decoded?.userEmail);
            setItem("userName", studentFirstName);
          }
        }

        if (isDashboard &&
         ( !isEmailValid ||
          !isPhoneValid ||
          Object.values(newErrors).some(Boolean))
        )
          return;
        if (parentUserName) {
          await createParent({
            ...userData,
            isParent: false,
            studentUserName: userName?.trim()?.toLowerCase(),
            studentPhoneNumber:
              formatPhoneNumberToInternational(studentPhoneNumber),
            parentPhoneNumber:
              formatPhoneNumberToInternational(parentPhoneNumber),
          }).unwrap();
        }
        if(!isDashboard){
          navigate("/dashboard");
        }else {
          associatedetails({})
        }
        setLoading(false);
        dispatch(resetUserDataForm());
        dispatch(closeDialog());
      }
    } catch (error: any) {
      const errorMessage = error.data?.message || "An error occurred.";
      toast.error(errorMessage);
      setLoading(false);
      console.error("Error:", error);
    }
  };


  const handleEmailExists = async () => {
    if (isDashboard) {
      try {
        const res = await isEmailExists({ email: parentUserName }).unwrap();
        console.log(res, "res6789");
        if (res.status === "success") {
          setError("");
        }
      } catch (error: any) {
        console.error("Error:", error);
        const errorMessage = error.data || "An error occurred.";
        if (error.originalStatus !== 200) {
          dispatch(setUserDataErrors({ parentUserName: true }));
          setLoading(false);
          setError(errorMessage);
          console.error("Error:", error);
        }
      }
    }
  };

  return (
    <>
      {userData.isExpired ? (
        <LinkExpired />
      ) : (
        <div
          className={`font-gully ${
            isParentRoute
              ? "flex h-screen justify-center items-center flex-col"
              : ""
          }`}
        >
          {(currentStep === "studentParentDetails" || isParentRoute) &&
            !isDashboard && (
              <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer mb-9">
                Enter parent’s detail
              </p>
            )}
          {(error || passwordError) && (
            <div className="min-w-[446px] max-w-[520px] flex items-center gap-x-1  py-3 px-5 mb-9 border border-[#d00000] rounded-xl">
              <div>
                <GrCircleAlert size={24} className="text-[#D00000] mr-[10px]" />
              </div>
              <p className="text-[#D00000] text-base font-semibold">
                {passwordError || error}
              </p>
            </div>
          )}
          <ParentDetailsForm
            handleFinishClick={handleFinishClick}
            isParentRoute={isParentRoute}
            handleChange={handleChange}
            handlePhoneChange={handlePhoneChange}
            setConfirmPassword={setConfirmPassword}
            confirmPassword={confirmPassword}
            setError={setError}
            loading={loading}
            isDashboard={isDashboard}
            handleEmailExists={handleEmailExists}
          />
        </div>
      )}
    </>
  );
};

export default ParentDetails;
