import { createSlice } from "@reduxjs/toolkit";

interface LoginState {
  createAccount: boolean;
  emailError:string;
  mobileNumberError:string;
  forgetPassEmail:string;
  forgetPassEmailError:boolean;
  forgetPasswordSetter:forgetPassword;
  forgetPasswordError:string;
  forgetPassworrdUserId:string;
}
interface forgetPassword{
  passwordOne : string;
  passwordTwo : string;
}
const initialState: LoginState = {
  createAccount: false,
  emailError:"",
  mobileNumberError:"",
  forgetPassEmail:"",
  forgetPassEmailError:false,
  forgetPasswordSetter:{
    passwordOne : "",
    passwordTwo : "",
  },
  forgetPasswordError:"",
  forgetPassworrdUserId:"",
};
const loginSlice = createSlice({
  name: "Store",
  initialState,
  reducers: {
    setCreateAccount: (state, action) => {
      state.createAccount = action.payload;
    },
    setEmailError:(state,action)=>{
      state.emailError = action.payload
    },
    setMobileNumberError:(state,action)=>{
      state.mobileNumberError = action.payload
    },
    setForgetPassEmail:(state,action)=>{
      state.forgetPassEmail = action.payload
    },
    setForgetPassEmailError:(state,action)=>{
      state.forgetPassEmailError = action.payload
    },
    setForgetPasswordSetter:(state,action)=>{
      const {name,value} = action.payload
      if(name === "passwordOne"){
        state.forgetPasswordSetter.passwordOne = value;
      }else if(name === "passwordTwo"){
        state.forgetPasswordSetter.passwordTwo = value;
      }
    },
    setForgetPasswordError:(state,action)=>{
      state.forgetPasswordError = action.payload
    },
    setForgetPasswordUserId:(state,action)=>{
      state.forgetPassworrdUserId = action.payload
    }
  },
});
export const { setCreateAccount,setEmailError,setMobileNumberError,setForgetPassEmail,setForgetPassEmailError,setForgetPasswordSetter,setForgetPasswordError,setForgetPasswordUserId } = loginSlice.actions;
export default loginSlice.reducer;
