import { Disclosure } from "@headlessui/react";
import arrowbar from "../../assets/Images/arrow-bar.png";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import RenderText from "../ReUsableComponents/RenderText";

const Explanation = () => {
  const { currentQuestion } = useSelector(
    (state: RootState) => state.root.resultsSlice
  );

  const { completedTestData, qId } = useSelector(
    (state: RootState) => state.root.startFullTest
  );
  const questionData = completedTestData?.additionalProps?.overallresult?.[
    currentQuestion
  ]?.find((q: any) => q._id === qId);

  return (
    <div>
      <Disclosure defaultOpen>
        {({ open }) => (
          <div>
            <div className="flex items-center justify-between text-[#327AEC]">
              <h3 className="text-lg font-medium">Explanation</h3>
              <Disclosure.Button className="flex items-center space-x-2 text-blue-500">
                <img
                  src={arrowbar}
                  alt="arrowbar"
                  className={`transform transition-transform font-medium ${
                    open ? "rotate-180" : ""
                  }`}
                />
              </Disclosure.Button>
            </div>

            <Disclosure.Panel>
              <div className="py-2 rounded-xl leading-[21.6px]">
                <RenderText
                  text={questionData?.explanation}
                  imageDetails={questionData}
                  isOptions={false}
                  questionId={questionData?._id}
                  contentType="explanation"
                />
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export default Explanation;
