import { useEffect, useState } from "react";
import ResultsTab from "./ResultPageTabs";
import {
  useGetUserResultsQuery,
  useLazyGetStartTestDataV1Query,
  useLazyRetrieveTestQuery,
  useResumeTestQuery,
} from "../../redux/API/StartTest/startTestApi";
import {  setItem } from "../../utils/token";
import { getFullName } from "../../utils";
import { IoIosArrowDown, IoIosArrowUp, IoIosPlay } from "react-icons/io";
import AuthButton from "../LogInPage/LoginButton";
import { useDispatch, useSelector } from "react-redux";
import { clearAnswers, retrieveMathsModuleOneAnswers, retrieveMathsModuleTwoAnswers, retrieveReadingModuleOneAnswers, retrieveReadingModuleTwoAnswers, setCurrentQuestion, setCurrentQuestionIndex } from "../../redux/Slices/resultSlice";
import {
  setTestId,
  setTestName,
  setReadingModuleOneQuestions,
  setMathModuleOneQuestions,
  setReadingModuleTwoQuestions,
  setMathModuleTwoQuestions,
  setUserTestId,
  changeCurrentModuleName,
  setCount,
  setCurrentTime,
  setReviewPageClicked,
  setIsModuleCompleted,
  setIsPopUp,
  setHighlightedContent,
  setStrikedOptionsData,
  StartedTestId,
  setStartedTestId,
  setResetFilters,
  setQid,
  setIsTestStarted,
  setIsTestInstructionsOpen,
  setIsResumeTest,
  setHasHighlightPopupBeenShown,
} from "../../redux/Slices/startFullTestSlice";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../redux/Store/store";
import FullTest from "../FullTest";
import TestInstructions from "../../components/Exam/TestInstructions";
import { RxHome } from "react-icons/rx";
import useContactUsIcon from "../../components/customHook/useContactUsIcon";

const ResultsPage = () => {
  const { data: resultData, isLoading: isResultLoading } = useGetUserResultsQuery("", { refetchOnMountOrArgChange: true });

  const [getStartTestDataV1] = useLazyGetStartTestDataV1Query();
  const [retrieveTest] = useLazyRetrieveTestQuery();
  const {data,isSuccess,isError} = useResumeTestQuery({},{refetchOnMountOrArgChange:true});

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [startDisable,setStartDisable] = useState(false);

  const { startedTestId, isTestStarted, isTestInstructionsOpen } = useSelector((state: RootState) => 
    state.root.startFullTest
  )


  const calculateExactAnswer = (options: any, answerValue: any) => {
    if (options.length > 0) {
      let correctOption = options.find((item: any) => item.isCorrectAnswer);
      return correctOption.answerOption;
    } else {
      return answerValue;
    }
  };

  const addQuestionId = (questions: any) => {
    return questions.map((item: any, index: any) => ({
      ...item,
      qIdUi: index + 1,
      exactAnswer: calculateExactAnswer(item.answers, item.answerValue),
    }));
  };

  const startTest = async (isPreview: boolean) => {
    try {
      let response = await getStartTestDataV1(isPreview);
      if (response.isSuccess) {
        dispatch(setIsTestInstructionsOpen(true));
        if (response.data.data) {
          localStorage.setItem(
            "currentTestName",
            response.data.data.testData.name
          );
          dispatch(setTestId(response.data.data.testData.testId));
          dispatch(setTestName(response.data.data.testData.name));

          let questions = response.data.data.testData.questions;
          let readingQuestions = questions[`ReadingAndWriting`];
          let mathsQuestions = questions[`Mathematics`];
          let readingModuleOneQuestions = [];
          let readingModuleTwoQuestions = [];
          let mathsModuleOneQuestions = [];
          let mathsModuleTwoQuestions = [];

          if (response.data.data.testData.testType === "PREVIEW") {
            setItem("testType", "PREVIEW");
            readingModuleOneQuestions = addQuestionId(
              readingQuestions[`Module1`]
            );
            mathsModuleOneQuestions = addQuestionId(mathsQuestions[`Module1`]);
            dispatch(setReadingModuleOneQuestions(readingModuleOneQuestions));
            dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
          } else {
            readingModuleOneQuestions = addQuestionId(
              readingQuestions[`Module1`]
            );
            readingModuleTwoQuestions = addQuestionId(
              readingQuestions[`Module2`]
            );
            mathsModuleOneQuestions = addQuestionId(mathsQuestions[`Module1`]);
            mathsModuleTwoQuestions = addQuestionId(mathsQuestions[`Module2`]);
            dispatch(setReadingModuleOneQuestions(readingModuleOneQuestions));
            dispatch(setReadingModuleTwoQuestions(readingModuleTwoQuestions));
            dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
            dispatch(setMathModuleTwoQuestions(mathsModuleTwoQuestions));
          }
          dispatch(setUserTestId(response.data.data.testData.userTestId));
        }

        dispatch(changeCurrentModuleName("writingModule1"));
        setStartDisable(false);
      } else {
        setStartDisable(false);
        console.error("Error:", response.error);
      }
    } catch (e) {
      setStartDisable(false);
      console.error("Error:", e);
    }
  };

  const handleStartTest = () => {
    dispatch(setCount(""));
    setStartDisable(true);
    dispatch(setHasHighlightPopupBeenShown(false));
    setItem("testType", "FULL");
    startTest(false);
    dispatch(setCurrentTime(1920));
    dispatch(clearAnswers());
    dispatch(setReviewPageClicked(false));
    dispatch(setIsModuleCompleted(false));
    dispatch(setIsPopUp(false));
     dispatch(setResetFilters());
     dispatch(setQid(""));
     dispatch(setCurrentQuestion(""));
     dispatch(setCurrentQuestionIndex(""));    
     dispatch(setIsTestStarted(false));
     dispatch(setIsResumeTest(false));
  };

  const resumeTest = async (item: StartedTestId) => {

    try {
      if (item.testId) {
        const response = await retrieveTest(item.testId);
        if(response.isSuccess){
        let moduleName =
          response.data?.data?.additionalProps?.currentModuleName;
        let count = response.data?.data?.additionalProps?.count;
        localStorage.setItem("currentTestName", item.testName);
        dispatch(setTestId(item.testId));
        dispatch(setTestName(item.testName));
        let strikedAndMarkedData = response.data?.data?.additionalProps?.strikedAndMarkedData;
        let highlightedContent = response.data?.data?.additionalProps?.highlightedContent;
        let time = response.data?.data?.additionalProps?.currentTime;
        let reviewPage = response.data?.data?.additionalProps?.reviewPageClicked;
        dispatch(setStrikedOptionsData(strikedAndMarkedData));
        dispatch(setHighlightedContent(highlightedContent));
        dispatch(setReviewPageClicked(reviewPage));
        if (response.data.data) {
          let questions = response.data.data.questionData;
          let answersData = response.data.data.answerData;

          let readingQuestions = questions[`ReadingAndWriting`];
          let mathsQuestions = questions[`Mathematics`];
          let readingModuleOneQuestions = [];
          let readingModuleTwoQuestions = [];
          let mathsModuleOneQuestions = [];
          let mathsModuleTwoQuestions = [];

          let mathAnswers = answersData[`Mathematics`];
          let readingAnswers = answersData[`ReadingAndWriting`];
          dispatch(setTestId(item.testId));
          dispatch(setUserTestId(response.data.data.userTestId));

          readingModuleOneQuestions = addQuestionId(
            readingQuestions[`Module1`]
          );
          readingModuleTwoQuestions = addQuestionId(
            readingQuestions[`Module2`]
          );
          mathsModuleOneQuestions = addQuestionId(mathsQuestions[`Module1`]);
          mathsModuleTwoQuestions = addQuestionId(mathsQuestions[`Module2`]);
          dispatch(setReadingModuleOneQuestions(readingModuleOneQuestions));
          dispatch(setReadingModuleTwoQuestions(readingModuleTwoQuestions));
          dispatch(setMathModuleOneQuestions(mathsModuleOneQuestions));
          dispatch(setMathModuleTwoQuestions(mathsModuleTwoQuestions));
          dispatch(retrieveMathsModuleOneAnswers(mathAnswers[`Module1`]));
          dispatch(retrieveMathsModuleTwoAnswers(mathAnswers[`Module2`]));
          dispatch(retrieveReadingModuleOneAnswers(readingAnswers[`Module1`]));
          dispatch(retrieveReadingModuleTwoAnswers(readingAnswers[`Module2`]));
          dispatch(changeCurrentModuleName(moduleName));
          dispatch(setCount(count));
          dispatch(setCurrentTime(time));
          dispatch(setIsTestStarted(true));
          dispatch(setIsTestInstructionsOpen(false));
         }
        }
        setStartDisable(false);
      }
    } catch (e) {
      setStartDisable(false);
      console.error(e, "Resume Test");
    }
  };

  const handleResumeTest = () => {
    setStartDisable(true);
    dispatch(setHasHighlightPopupBeenShown(false));
    resumeTest(startedTestId)
    dispatch(setReviewPageClicked(false));
    dispatch(setIsModuleCompleted(false));
    dispatch(setIsPopUp(false));
    setItem("testType", "FULL");
    dispatch(setResetFilters());
    dispatch(setQid(""));
    dispatch(setCurrentQuestion(""));
    dispatch(setCurrentQuestionIndex(""));
    dispatch(setIsResumeTest(true));
  }

  const handleHomeBtn = () => {
    navigate('/dashboard');
    dispatch(setIsTestStarted(false));
  }

  useEffect(() => {
    if(isSuccess && data){
     let payload = {
       testId : data.testId ?? "",
       testName: data.testname ?? "",
       createdDate: data.createdDate ?? "",
     }
     dispatch(setStartedTestId(payload));
    }
    if(isError){
     let payload = {
       testId : "",
       testName: "",
       createdDate: "",
     }
     dispatch(setStartedTestId(payload));
    }
   },[data, isSuccess, isError, dispatch]);

   useEffect(() => {
     dispatch(setResetFilters());
     dispatch(setQid(""));
     dispatch(setCurrentQuestion(""));
     dispatch(setCurrentQuestionIndex(""));
   }, [dispatch]);

  return (
    <>
    {isTestStarted ? <FullTest /> : isTestInstructionsOpen ? <TestInstructions /> :
    <div className="w-[70%] mx-auto flex flex-col gap-9 my-12">
      <div className="flex justify-between">
        <div className="w-full">
          <AuthButton
            text="Return To Home"
            icon={<RxHome size={16}/>}
            iconPosition="left"
            onClick={handleHomeBtn}
            className="font-gully"
          />
        </div>
        <div className="w-full flex justify-end">
          {startedTestId.testId === "" ? (
            <AuthButton
              text="Start Full Length Pratice Test"
              onClick={handleStartTest}
              disabled={startDisable}
              className="font-gully"
              icon={<IoIosPlay />}
              iconPosition="left"
            />) : (
            <AuthButton
              text="Resume Full Length Pratice Test"
              onClick={handleResumeTest}
              disabled={startDisable}
              className="font-gully "
              icon={<IoIosPlay  size={24}/>}
              iconPosition="left"
            />
            )}
        </div>
      </div>
      
      <ResultsTab resultsData={resultData?.data} isLoading={isResultLoading} />
      
    </div>
    }
    </>
  );
};

export default ResultsPage;

export type StudentDetail = {
  firstName: string;
  id: string;
  lastName: string;
  county: string;
  school: string;
};
type StudentDetailProps = {
  studentName: string;
  studentDetailData?: StudentDetail[];
  handleStudentSelection?: (id: string) => void;
};

export const StudentCountyDetails = ({
  studentDetailData,
  studentName,
  handleStudentSelection,
}: StudentDetailProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleSelect = (id: string, isSelected: boolean) => {
    if (!isSelected) {
      handleStudentSelection?.(id);
    }
    setDropdownOpen(!dropdownOpen);
  };

  const isDropdownAvailable = studentDetailData && studentDetailData.length > 0;

  return (
    <div className="flex flex-col font-gully w-fit items-start gap-[10px] relative">
      <div className="text-lg flex space-x-2 font-bold text-appPrimaryColor z-10">
        <h3>{studentName ?? "-"}</h3>
        {isDropdownAvailable && (
          <button onClick={() => setDropdownOpen(!dropdownOpen)}>
            {dropdownOpen ? (
              <IoIosArrowUp className="w-6 h-6 my-auto" />
            ) : (
              <IoIosArrowDown className="w-6 h-6 my-auto" />
            )}
          </button>
        )}
      </div>

      {dropdownOpen && (
        <div className="absolute top-6 right-0 bg-white rounded-xl shadow-lg w-48 max-h-52 overflow-y-auto">
          {isDropdownAvailable ? (
            studentDetailData.map((student, index) => {
              const fullName = getFullName(student.firstName, student.lastName);
              const isSelected = fullName === studentName;

              return (
                <button
                  key={student.id}
                  className={`py-[18px] px-[14px] w-full text-base font-gully font-medium text-start hover:bg-appPrimaryColor hover:text-white text-gray-900
                  ${index === 0 ? "rounded-t-xl" : ""}
                  ${
                    index === studentDetailData.length - 1 ? "rounded-b-xl" : ""
                  }
                  ${isSelected ? "bg-appPrimaryColor text-white" : ""}`}
                  onClick={() => handleSelect(student.id, isSelected)}
                >
                  {fullName.length > 12
                    ? `${fullName.substring(0, 12)}...`
                    : fullName}
                </button>
              );
            })
          ) : (
            <div className="py-[18px] px-[14px] text-gray-500">
              No students available
            </div>
          )}
        </div>
      )}
    </div>
  );
};
