import React, { useCallback, useEffect, useState } from 'react'
import CustomButton from '../ReUsableComponents/CustomButton';
import CommonTestModuleLayout from '../ReUsableComponents/CommonTestModuleLayout';
import { changeCurrentModuleName, setCount, setCurrentTime, setIsModuleCompleted, setIsPopUp, setQuestionId, setReviewPageClicked, setSpentTime } from '../../redux/Slices/startFullTestSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectFullTestState } from '../../redux/Selectors/selectorsUse';
import { next } from '../../utils/commonLogic';
import { RootState } from '../../redux/Store/store';
import { getItem } from '../../utils/token';
import { ActivityData } from '../customHook/useUserTestActivity';
import useSendTestActivity from '../customHook/useUserTestActivity';

const WritingModuleTwo = () => {
    const dispatch = useDispatch();
    const {startTestSlice,resultSlice} = useSelector(selectFullTestState);
    const currentTime = useSelector((state:RootState) => state.root.startFullTest.currentTime);
    const { reviewPageClicked, userTestId, count, readingModuleTwoQuestions } =
      useSelector((state: RootState) => state.root.startFullTest);
    const [startTime, setStartTime] = useState<Date | null>(null);
    const [isNextDisabled, setIsNextDisabled] = useState(false);
    const sendTestActivity = useSendTestActivity();
    const isPreview = getItem("testType") === "PREVIEW";

    useEffect(() => {
      setStartTime(new Date());
    }, [count]);
    const saveHandler = () => {
      // dispatch(openDialog("writingInstructions"));
    };
    
      const heading = useCallback(() => {
        return (
          <div className="flex flex-col">
            <div className="text-lg font-semibold">
              <p>Section 1,Module 2: Reading and Writing</p>
              <p className="text-center">Questions</p>
            </div>
          </div>
        );
      },[]);
      const timer = () => {
        return <div>19:25</div>;
      };
      const exit = () => {
        return (
          <div>
            <CustomButton
              name="Save & Exit"
              onClickHandler={saveHandler}
              bg="bg-white"
              color="[#333]"
              font="font-bold"
              width="w-full"
              border="border-[#333]"
              py="py-2"
              round="full"
            />
          </div>
        );
      };
      const children = () => {
        return <div className="flex justify-center items-center">Writing Test Module TWO Children Component </div>;
      };
      const textName = () => {
        return <div className="font-semibold">  {getItem('currentTestName')} </div>;
      };
        let moduleCompleteTimeout: NodeJS.Timeout | null = null;
        let breakTimeTimeout: NodeJS.Timeout | null = null;
      const nexxtClickHandler = () => {
         if (!startTime || isNextDisabled) return;
         setIsNextDisabled(true);
         const endTime = new Date();
          dispatch(setReviewPageClicked(false));
        if (
          startTestSlice.count >
            startTestSlice.readingModuleTwoQuestions.length - 1 ||
          reviewPageClicked
        ) {
          dispatch(
            setSpentTime({
              field: "readingAndWritingModuleTwoSpentTime",
              value: +currentTime,
            })
          );
          dispatch(setIsModuleCompleted(true));
          moduleCompleteTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleComplete"));
          }, 500);
          breakTimeTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleBreakTime"));
            dispatch(setCurrentTime(600));
          }, 7000);
          dispatch(setCount(""));
        } else {
          setStartTime(endTime);
          dispatch(setCount("Increment"));
          if(!isPreview){
          const activityData: ActivityData = {
            testId: userTestId,
            startTime,
            endTime,
            module: 302,
            section: 702,
            fromQuestion: count + 1,
            toQuestion:
              count + 1 === readingModuleTwoQuestions.length
                ? count + 1
                : count + 2,
            event: 501,
            questionId: readingModuleTwoQuestions[count]._id,
          };
          dispatch(setQuestionId(readingModuleTwoQuestions[count]._id));
          sendTestActivity(activityData);
        }
        }
        dispatch(setIsPopUp(false))
        setTimeout(() => setIsNextDisabled(false), 500);
      };
    
       useEffect(() => {
         return () => {
           dispatch(setIsModuleCompleted(false));
           if (moduleCompleteTimeout) clearTimeout(moduleCompleteTimeout);
           if (breakTimeTimeout) clearTimeout(breakTimeTimeout);
         };
       }, []);
      useEffect(() => {
        dispatch(changeCurrentModuleName("writingModule2"))
         // dispatch(openDialog("writingInstructions"));
        dispatch(setIsModuleCompleted(false));
      },[])

      useEffect(() => {
        if (currentTime <= 0) {
          dispatch(setIsModuleCompleted(true));
          moduleCompleteTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleComplete"));
          }, 500);
          breakTimeTimeout = setTimeout(() => {
            dispatch(changeCurrentModuleName("moduleBreakTime"));
            dispatch(setCurrentTime(600));
          }, 7000);
          dispatch(setCount(""));
        }
      }, [currentTime]);

      return (
        <CommonTestModuleLayout
          heading={heading}
          timer={timer}
          exit={exit}
          textName={textName}
          next={() => next(nexxtClickHandler)}
          questionsList={startTestSlice.readingModuleTwoQuestions}
          moduleName={"writingModuleTwoAnswers"}
          userAnswers={resultSlice.writingModuleTwoAnswers}
          headerHeading={"Section 1, Module 2: Reading and Writing Questions"}
          setStartTime={setStartTime}
          startTime={startTime}
          module={302}
          section={702}
        >
          {children}
        </CommonTestModuleLayout>
      );
}

export default WritingModuleTwo