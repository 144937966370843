import ParentDetails from "./ParentDetails";
import StudentDetails from "./StudentDetails";
import { useDispatch, useSelector } from "react-redux";
import { resetUserDataForm, setCurrentStep, setSelectedRole } from "../../redux/Slices/profileSetupSlice";
import { RootState } from "../../redux/Store/store";
import { useNavigate } from "react-router-dom";
import { useLazyProfileStatusQuery } from "../../redux/API/ProfileSetup/profileSetupApi";
import { useEffect } from "react";

const ProfileSetup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedRole,currentStep} = useSelector(
    (state: RootState) => state.root.profileSetupSlice
  );

  const [profileStatus] = useLazyProfileStatusQuery();
  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {value} = event.target
    dispatch(setSelectedRole(value));
    dispatch(resetUserDataForm());
    if(value === 'parent'){
      dispatch(setCurrentStep('parentPersonalDetails'))
    }else {
      dispatch(setCurrentStep('studentPersonalDetails'))
    }
  };

  const getProfileStatus = async () => {
    try {
      const response = await profileStatus({}).unwrap();
      if (response.status === "success") {
        if(response?.data?.isProfileVerified){
          navigate("/dashboard");
        } 
      }else{
        navigate("/login");
      }
    } catch (error) {
      navigate('/login');
      console.error(error);
    }
  }

  useEffect(() => {
    getProfileStatus();
  },[])

  return (
    <div className="flex h-screen justify-center items-center flex-col font-gully">
      <div className="h-[685px]">
        <p className="font-bold text-[#327AEC] text-center text-[55px] mb-9 font-pretendard cursor-pointer">
          S A T
        </p>
        {currentStep === "studentParentDetails" &&
        selectedRole === "student" ? (
          <ParentDetails />
        ) : currentStep === "parentStudentDetails" &&
          selectedRole === "parent" ? (
          <StudentDetails />
        ) : (
          <>
            <p className="font-bold text-[#327AEC] text-center text-[40px] font-pretendard cursor-pointer">
              Lets setup your profile
            </p>
            <div className="w-full my-9">
              <div className="flex justify-center items-center text-[21px] font-medium">
                <p className="mr-9">I am a </p>
                <label className="role">
                  <span className="ml-3 mr-9">Student</span>
                  <input
                    value="student"
                    type="radio"
                    name="radio"
                    checked={selectedRole === "student"}
                    onChange={handleRoleChange}
                  />
                  <span className="checkmark"></span>
                </label>{" "}
                <label className="role">
                  <span className="ml-3">Parent</span>
                  <input
                    value="parent"
                    type="radio"
                    name="radio"
                    checked={selectedRole === "parent"}
                    onChange={handleRoleChange}
                  />
                  <span className="checkmark"></span>
                </label>{" "}
              </div>
            </div>
            {/* student */}
            <div className="w-[446px]">
              {selectedRole === "student" ? (
                <StudentDetails />
              ) : (
                <ParentDetails />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileSetup;
