import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";
import AuthButton from "../LogInPage/LoginButton";
import { getItem, setItem } from "../../utils/token";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/ReUsableComponents/Loader";
import { setMinimisePanel, setResetFilters } from "../../redux/Slices/startFullTestSlice";
import { useDispatch } from "react-redux";
import { DashboardWidgets } from "../NewDashboard/DashboardCards";
import { AssessmentTabProps } from "../NewDashboard/Widgets/dataTypes";

const ResultPageTabs = ({ resultsData, isLoading, studentId }: any) => {
  return (
    <div>
      <Tab.Group>
        <Tab.List className="relative flex">
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`font-gully px-[10px] rounded-t text-base border border-b-0 focus:outline-none transition-all ${
                  selected
                    ? "text-appPrimaryColor py-[11px] border-appPrimaryColor"
                    : "bg-[#F5F9FF] py-2 text-[#646464] border-[#646464] mt-1"
                }`}
                role="tab"
              >
                Results
              </button>
            )}
          </Tab>
          <Tab as={Fragment}>
            {({ selected }) => (
              <button
                className={`font-gully ml-1 px-[10px]  rounded-t text-base border border-b-0 focus:outline-none transition-all ${
                  selected
                    ? "text-appPrimaryColor py-[11px] border-appPrimaryColor"
                    : "bg-[#F5F9FF] py-2 text-[#646464] border-[#646464] mt-1"
                }`}
                role="tab"
              >
                Assessment
              </button>
            )}
          </Tab>
        </Tab.List>

        <Tab.Panels >
          <Tab.Panel>
            <ResultTab resultData={resultsData} isLoading={isLoading} />
          </Tab.Panel>
          <Tab.Panel>
            <AssessmentTab studentId={studentId}/>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default ResultPageTabs;

export const ResultTab = ({ resultData, isLoading }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleViewResult = (testId: string, testName: string) => {
    if (testId && testName) {
      let currentTest = resultData.find((item: any) => item?.testId === testId);
      let updatedResults: any = currentTest?.additionalProps?.overallresult;
      setItem("overallResults", JSON.stringify(updatedResults));
      setItem("currentTestName", testName);
      navigate(`/practices/results/${testId}`);
      dispatch(setMinimisePanel(false));
      dispatch(setResetFilters());
    }
  };
  const formatDate = (dateString: string) => {
    if (dateString) {
      const date = new Date(dateString);

      const day = date.getDate();
      const month = date.toLocaleString("en-US", { month: "short" });
      const year = String(date.getFullYear()).slice(-2);

      const getOrdinalSuffix = (day: number) => {
        if (day % 10 === 1 && day !== 11) return "st";
        if (day % 10 === 2 && day !== 12) return "nd";
        if (day % 10 === 3 && day !== 13) return "rd";
        return "th";
      };

      const ordinal = getOrdinalSuffix(day);
      return `${day}${ordinal} ${month} '${year}`;
    }
    return "N/A";
  };
  const userRole = getItem("userRole");
  return (
    <>
      <div
        className="h-[1px] flex ml-auto bg-appPrimaryColor overflow-y-auto"
        style={{ width: "calc(100% - 76px)" }}
      ></div>
      {isLoading ? (
        <div className="flex justify-center py-10 rounded-b-xl shadow-md">
          <Loader />
        </div>
      ) : (
        <div className="w-full py-6 max-h-[65vh] rounded-b-xl shadow-md  overflow-y-auto">
          {/* Header */}
          <div className="grid grid-cols-4 gap-4 pb-2">
            <h3 className="p-[10px] text-center font-gully text-[21px] font-medium">
              Test Name
            </h3>
            <h3 className="p-[10px] text-center font-gully text-[21px] font-medium">
              Date Taken
            </h3>
            <h3 className="p-[10px] text-center font-gully text-[21px] font-medium">
              Score
            </h3>
            <h3 className="p-[10px] text-center font-gully text-[21px] font-medium">
              Results
            </h3>
          </div>

          {/* Data Rows */}
          {resultData?.length > 0 ? (
            resultData.map((item: any) => (
              <div
                key={item.testId}
                className="grid grid-cols-4 gap-4 items-center py-2 mt-6"
              >
                <h3 className="p-3 text-center font-gully text-base font-normal">
                  {item.name ?? "N/A"}
                </h3>
                <h3 className="p-3 text-center font-gully text-base font-normal">
                  {formatDate(item?.testTakenDate)}
                </h3>
                <h3 className="p-3 text-center font-gully text-base font-normal">
                  {item?.finalScore?.totalScaledScore ?? "N/A"}
                </h3>
                <div className="block mx-auto">
                  <button
                    onClick={() => handleViewResult(item?.testId, item?.name)}
                    className="flex w-32 py-2 px-3 justify-center items-center text-[16px] font-bold font-gully bg-appPrimaryColor rounded-md text-white hover:bg-white hover:text-appPrimaryColor border border-[#327AEC]"
                    style={{
                      boxShadow: "0px 4px 8px 0px rgba(28, 37, 44, 0.08)",
                    }}
                  >
                    View Result
                  </button>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full flex justify-center">
              {userRole === "PARENT" && (
                <div className="font-gully text-xl text-center">
                  No tests have been attempted yet
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const AssessmentTab = ({studentId}:AssessmentTabProps) => {
  return (
    <>
      <div className="flex justify-between w-full">
        <div className="h-[1px] w-[82px] flex bg-appPrimaryColor"></div>
        <div
          className="h-[1px] flex ml-auto  bg-appPrimaryColor"
          style={{ width: "calc(100% - 194px)" }}
        ></div>
      </div>
      <div className="py-10">
        <DashboardWidgets studentId={studentId}/>
      </div>
    </>
  );
};
