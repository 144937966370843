import { ChartOptions } from "chart.js";
import { Line } from "react-chartjs-2";
import { formatDateForWidgets } from "../../../utils";
import { TestTimeData, ScoreGraphData, TimeWidgetProps } from "./dataTypes";

const TimeWidget = ({ timeData }: TimeWidgetProps) => {
    const options: ChartOptions<"line"> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: "bottom",
          
          labels: {
            usePointStyle: false,
            font: {
              size: 12,
              family: "Gully",
            },
            boxWidth: 14,
            boxHeight: 14,
            textAlign: "center",
            generateLabels: (chart) => {
              return chart.data.datasets.map((dataset, index) => ({
                text: dataset.label || "Unnamed Dataset",
                fillStyle: dataset.borderColor as string,
                hidden: !chart.isDatasetVisible(index),
                strokeStyle: dataset.borderColor as string,
                lineWidth: 1,
                datasetIndex: index,
              }));
            },
          },
        },
        tooltip: {
          callbacks: {
            title: function (tooltipItems) {
              const index = tooltipItems[0].dataIndex;
              const testName = `${timeData[index]?.name}` || "Unnamed Test";
              return testName;
            },
            label: function (tooltipItem) {
              return `${tooltipItem.dataset.label}: ${tooltipItem.raw} sec`;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
      scales: {
        x: {
          offset: true,
          ticks: {
            color: "#000",
            align: "center",
            font:{
              size: 10,
              weight: "bold",
              family: "Gully",
            }
          },
          grid: {
            drawTicks: true,
            display: false,
          },
          title: {
            display: true,
            text: "TEST DATES",
            color: "#000",
            font: {
              size: 12,
              weight: "bold",
              family: "Gully",
            },
          },
        },
        y: {
          beginAtZero: true,
          grid: {
            display: true,
          },
          title: {
            display: true,
            text: "AVERAGE TIME (SECONDS)",
            color: "#000",
            font: {
              size: 12,
              weight: "bold",
              family: "Gully",
            },
          },
          ticks:{
            font:{
              size: 10,
              weight: "bold",
              family: "Gully",
            }
          }
        },
      },
    };
  
    const generateData = (data: TestTimeData[]) => {
      let requiredData: ScoreGraphData = {
        labels: [],
        datasets: [],
      };
  
      if (!data) {
        return requiredData;
      }
  
      data.forEach((item) => {
        const formattedDate = formatDateForWidgets(item.testtakendate);
        requiredData.labels.push(formattedDate);
      });
  
      const avgreadingwritingtime = data.map((item) =>
        parseFloat((item.time.avgreadingwritingtime / 1000).toFixed(1))
      );
      const avgmathstime = data.map((item) =>
        parseFloat((item.time.avgmathstime / 1000).toFixed(1))
      );
      const overallavgtime = data.map((item) =>
        parseFloat((item.time.overallavgtime / 1000).toFixed(1))
      );
  
      requiredData.datasets = [
        {
          label: "R&W Avg",
          data: avgreadingwritingtime,
          borderColor: "#4A2545",
          borderWidth: 2,
          pointBackgroundColor: "#4A2545",
          tension: 0.0,
          fill: false,
        },
        {
          label: "Math Avg",
          data: avgmathstime,
          borderColor: "#2CDA9D",
          borderWidth: 2,
          pointBackgroundColor: "#2CDA9D",
          tension: 0.0,
          fill: false,
        },
        {
          label: "Overall Avg",
          data: overallavgtime,
          borderColor: "#EAC435",
          borderWidth: 2,
          pointBackgroundColor: "#EAC435",
          tension: 0.0,
          fill: false,
        },
      ];
  
      return requiredData;
    };
  
    const chartData = generateData(timeData);
  
    return (
      <div
        className="w-full flex py-3 px-6 flex-col justify-between rounded-xl"
        style={{ boxShadow: "0px 0px 10px 3px rgba(32, 32, 32, 0.10)" }}
      >
        <p className="text-center font-gully text-[21px] font-medium">
          Avg time spent on a question
        </p>
        <div className="w-full min-h-[300px]">
          <Line data={chartData} options={options} />
        </div>
      </div>
    );
};

export default TimeWidget;