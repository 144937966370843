import AuthButton from "../LogInPage/LoginButton";
import { Chart as ChartJS,CategoryScale,LinearScale,PointElement,LineElement,Title,Tooltip,Legend,ChartOptions,BarElement,BarController } from "chart.js";
import { useEffect, useState } from "react";
import { useGetWidgetDataQuery } from "../../redux/API/Login/loginApi";
import DomainWidget from "./Widgets/DomainWidget";
import ScoreWidget from "./Widgets/ScoreWidget";
import TimeWidget from "./Widgets/TimeWidget";
import { AssessmentTabProps, PerformanceData, TestResult, TestTimeData } from "./Widgets/dataTypes";

ChartJS.register(CategoryScale,LinearScale,PointElement,LineElement,BarElement, BarController,Title,Tooltip,Legend);

interface TestCardProps {
  icon: React.ReactNode;
  heading: string;
  btnTitile: string;
  btnIcon: React.ReactNode;
  onClickHandler: () => void;
  btnDisabled?: boolean;
  tooltipPreview?: boolean;
}

interface DashboardScoreCardProps {
  headerTitle: string;
  footerTitle: string;
  content: JSX.Element;
}

export const TestCards = ({
  icon,
  heading,
  btnIcon,
  btnTitile,
  onClickHandler,
  btnDisabled,
  tooltipPreview,
}: TestCardProps) => {
  return (
    <div className="flex w-64 p-6 flex-col items-center gap-9 shrink-0 self-stretch rounded-xl shadow-test-card">
      <div className="flex flex-col items-center gap-3 self-stretch">
        <div className="w-[70px] h-[70px]">{icon}</div>
        <h3 className="font-gully text-3xl font-bold text-center">{heading}</h3>
      </div>
      <div className="w-full flex justify-center relative group">
        <AuthButton
          text={btnTitile}
          icon={btnIcon}
          iconPosition="left"
          onClick={onClickHandler}
          loader={false}
          disabled={btnDisabled ?? false}
        />
        {tooltipPreview && (
          <div className="absolute w-72 top-8 left-[19rem] font-gully transform -translate-x-1/2 mb-3 px-3 py-6 text-base text-center font-normal text-[#646464] bg-white rounded-xl shadow-lg opacity-0 group-hover:opacity-100 transition-opacity pointer-events-none">
            <p>Hey! You already have a Full Practice Test in progress.</p>
            <p className="mt-3">
              You can start a New Full Practice Test once you have completed it
              or discarded it.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export const DashboardScoreCard = ({
  headerTitle,
  footerTitle,
  content,
}: DashboardScoreCardProps) => {
  return (
    <div className="flex w-[30%] p-6 flex-col items-center rounded-xl justify-between shrink-0 self-stretch shadow-test-card">
      <h4 className="text-center font-gully text-[21px] font-medium">
        {headerTitle}
      </h4>
      {content}
      <p className="text-center font-gully text-lg font-normal">
        {footerTitle}
      </p>
    </div>
  );
};

export const DashboardWidgets = ({studentId}:AssessmentTabProps) => {
  const [message, setMessage] = useState("");
  const queryArgs = studentId ? {id : studentId} : {};
  const {
    data: widgetData,
    isLoading: widgetLoading,
    isSuccess: widgetSuccess,
    isError: widgetError,
  } = useGetWidgetDataQuery(queryArgs, { refetchOnMountOrArgChange: true });

  const getMessage = () => {
    if (widgetLoading) {
      return "Loading...";
    }
    if (widgetError) {
      return "Apologies, there's an issue on our end. Please try again later.";
    }
    if (Object.keys(widgetData?.data).length === 0) {
      return "Complete a full practice test to view data";
    }
    return "";
  };

  useEffect(() => {
    setMessage(getMessage());
  }, [widgetData, widgetLoading, widgetError, widgetSuccess]);

  const isBlurred = message !== "";

  const sortedScoreData = Array.isArray(widgetData?.data?.overalltestprogress)
  ? [...widgetData?.data?.overalltestprogress].sort((a: TestResult, b: TestResult) =>
      new Date(a.testtakendate).getTime() - new Date(b.testtakendate).getTime()
    )
  : [];

  const sortedTimeData = Array.isArray(widgetData?.data?.avgtimepertest)
  ? [...widgetData?.data?.avgtimepertest].sort((a: TestTimeData, b: TestTimeData) =>
      new Date(a.testtakendate).getTime() - new Date(b.testtakendate).getTime()
    )
  : [];
  
  const domainData = widgetData?.data?.performancebydomain;
  const sortedDomainData = Array.isArray(widgetData?.data?.performancebydomain)
  ? [...widgetData?.data?.performancebydomain].sort((a: PerformanceData, b: PerformanceData) =>
    new Date(a.testtakendate).getTime() - new Date(b.testtakendate).getTime()
    )
  : [];

  return (
    <div className="w-full flex items-center justify-between gap-[2%] relative">
      <div
        className={`w-full flex items-center justify-between gap-[2%] ${
          isBlurred ? "blur-md pointer-events-none" : ""
        }`}
      >
        <div className="w-[32%] flex justify-between shrink-0 self-stretch">
          <ScoreWidget overallResult={sortedScoreData} />
        </div>
        <div className="w-[32%] flex justify-between shrink-0 self-stretch">
          <TimeWidget timeData={sortedTimeData} />
        </div>
        <div className="w-[32%] flex justify-between shrink-0 self-stretch">
          <DomainWidget domainResults={sortedDomainData} />
        </div>
      </div>

      {isBlurred && (
        <div className="absolute inset-0 flex items-center justify-center bg-opacity-70">
          <p className="font-gully text-3xl font-bold text-center text-[#283044]">
            {message}
          </p>
        </div>
      )}
    </div>
  );
};

