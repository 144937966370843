import React, { ChangeEvent, useState } from "react";
import { BiSave } from "react-icons/bi";
import { MdArrowBackIosNew, MdOutlineRemoveRedEye } from "react-icons/md";
import AuthButton from "../../pages/LogInPage/LoginButton";
import { useDispatch } from "react-redux";
import { closeDialog } from "../../redux/Slices/dialogSlice";
import CustomTextField from "../ReUsableComponents/CustomTextField";
import { RiEyeCloseLine } from "react-icons/ri";
import {
  useCheckPasswordMutation,
  useUpdatePasswordMutation,
} from "../../redux/API/Login/loginApi";
import { encode } from "js-base64";
import { usePasswordValidation } from "../../utils";
import { GrCircleAlert } from "react-icons/gr";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const { validatePassword } = usePasswordValidation();

  const [checkPassword] = useCheckPasswordMutation();
  const [updatePassword] = useUpdatePasswordMutation();

  const [correctOldPassword, setCorrectOldPassword] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPass, setShowPass] = useState({
    checkPass: false,
    newPassOne: false,
    newPassTwo: false,
  });

  const [passwordData, setPasswordData] = useState({
    checkPass: "",
    newPassOne: "",
    newPassTwo: "",
  });

  const [error, setError] = useState({
    oldPassword: false,
    newPasswordOne: false,
    newPasswordTwo: false,
  });

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { name, value } = e.target;
    setPasswordData((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckOldPass = async () => {
    try {
      if (passwordData.checkPass.trim().length > 0) {
        setButtonLoading(true);
        const payload = {
          password: encode(passwordData.checkPass),
        };
        const response = await checkPassword(payload).unwrap();
        if (response.status === "success") {
          setError((prev) => ({
            ...prev,
            oldPassword: !response?.data?.correctPassword,
          }));
          setCorrectOldPassword(response?.data?.correctPassword);
          setButtonLoading(false);
          if (response?.data?.correctPassword === false) {
            setErrorMessage("Old password is incorrect");
          } else {
            setErrorMessage("");
          }
        } else {
          setErrorMessage(
            "There was an issue, please wait a moment and try again."
          );
          setCorrectOldPassword(false);
          setError((prev) => ({ ...prev, oldPassword: false }));
          setButtonLoading(false);
        }
      }
    } catch (error) {
      setErrorMessage(
        "There was an issue, please wait a moment and try again."
      );
      setCorrectOldPassword(false);
      setError((prev) => ({ ...prev, oldPassword: false }));
      setButtonLoading(false);
    }
  };

  const handlePasswordValidation = (field: "newPassOne" | "newPassTwo") => {
    if (passwordData[field].trim().length > 0) {
      const isValid = validatePassword(passwordData[field]);
      const errorKey =
        field === "newPassOne" ? "newPasswordOne" : "newPasswordTwo";

      if (!isValid) {
        setErrorMessage(
          "Password must be at least 8 characters long, contain at least one special character, and one uppercase letter."
        );
        setError((prev) => ({ ...prev, [errorKey]: true }));
      } else {
        setErrorMessage("");
        setError((prev) => ({ ...prev, [errorKey]: false }));
      }
    }
  };

  const getSaveBtnDisabled = () => {
    if (
      passwordData.newPassOne.trim().length === 0 ||
      passwordData.newPassTwo.trim().length === 0 ||
      passwordData.checkPass.trim().length === 0
    ) {
      return true;
    }

    if (error.oldPassword || error.newPasswordOne || error.newPasswordTwo) {
      return true;
    }

    return false;
  };

  const handleSave = async () => {
    if (passwordData.newPassOne !== passwordData.newPassTwo) {
      setErrorMessage("Passwords do not match.");
      return;
    } else if (passwordData.newPassOne === passwordData.checkPass) {
      setErrorMessage(
        "The new password cannot be the same as the old password."
      );
      return;
    } else {
      setErrorMessage("");
    }

    try {
      setButtonLoading(true);
      let payload = {
        newPassword: encode(passwordData.newPassOne),
        oldPassword: encode(passwordData.checkPass)
      };
      const response = await updatePassword(payload).unwrap();
      if (response.status === "success") {
        dispatch(closeDialog());
        setButtonLoading(false);
        setErrorMessage("");
      } else {
        setButtonLoading(false);
        setErrorMessage(response.message ?? "An error occurred.");
      }
    } catch(e) {
      console.log(e);
      setButtonLoading(false);
      setErrorMessage(
        "There was an issue, please wait a moment and try again."
      );
    }
  };

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  return (
    <div className="px-3 py-6 gap-9 flex flex-col items-center">
      <h1 className="font-gully text-[40px] font-bold text-center text-appPrimaryColor">
        Change password
      </h1>

      <div className="flex w-full flex-col flex-start gap-6">
        {errorMessage.length > 0 && (
          <div className="flex w-full items-center py-2 px-2 border border-[#d00000] rounded-xl">
            <div>
              <GrCircleAlert size={24} className="text-[#D00000] mr-[10px]" />
            </div>
            <p className="text-[#D00000] text-sm font-medium">{errorMessage}</p>
          </div>
        )}
        <div className="flex justify-between relative">
          <CustomTextField
            onChangeHandler={handlePassword}
            placeholder="Current Password"
            type={`${showPass.checkPass ? "text" : "password"}`}
            name="checkPass"
            mandatory={true}
            value={passwordData.checkPass}
            className="w-full !border-[#646464]"
            isError={error.oldPassword}
            onBlur={handleCheckOldPass}
          />
          {passwordData.checkPass && (
            <button
              className={`absolute ${
                error.oldPassword ? "right-12" : "right-6"
              } bottom-2 cursor-pointer text-2xl text-[#646464]`}
              onClick={() =>
                setShowPass((prev) => ({
                  ...prev,
                  checkPass: !showPass.checkPass,
                }))
              }
            >
              {showPass.checkPass ? (
                <MdOutlineRemoveRedEye />
              ) : (
                <RiEyeCloseLine />
              )}
            </button>
          )}
        </div>
        <div className="flex justify-between relative">
          <CustomTextField
            onChangeHandler={handlePassword}
            placeholder="New Password"
            type={`${showPass.newPassOne ? "text" : "password"}`}
            name="newPassOne"
            mandatory={true}
            value={passwordData.newPassOne}
            className="w-full !border-[#646464]"
            isError={error.newPasswordOne}
            onBlur={() => handlePasswordValidation("newPassOne")}
          />
          {passwordData.newPassOne && (
            <button
              className={`absolute ${
                error.newPasswordOne ? "right-12" : "right-6"
              } bottom-2 cursor-pointer text-2xl text-[#646464]`}
              onClick={() =>
                setShowPass((prev) => ({
                  ...prev,
                  newPassOne: !showPass.newPassOne,
                }))
              }
            >
              {showPass.newPassOne ? (
                <MdOutlineRemoveRedEye />
              ) : (
                <RiEyeCloseLine />
              )}
            </button>
          )}
        </div>
        <div className="flex justify-between relative">
          <CustomTextField
            onChangeHandler={handlePassword}
            placeholder="Re-enter New Password"
            type={`${showPass.newPassTwo ? "text" : "password"}`}
            name="newPassTwo"
            mandatory={true}
            value={passwordData.newPassTwo}
            className="w-full !border-[#646464]"
            isError={error.newPasswordTwo}
            onBlur={() => handlePasswordValidation("newPassTwo")}
          />
          {passwordData.newPassTwo && (
            <button
              className={`absolute ${
                error.newPasswordTwo ? "right-12" : "right-6"
              } bottom-2 cursor-pointer text-2xl text-[#646464]`}
              onClick={() =>
                setShowPass((prev) => ({
                  ...prev,
                  newPassTwo: !showPass.newPassTwo,
                }))
              }
            >
              {showPass.newPassTwo ? (
                <MdOutlineRemoveRedEye />
              ) : (
                <RiEyeCloseLine />
              )}
            </button>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center self-stretch">
        <AuthButton
          text="Back"
          icon={<MdArrowBackIosNew size={16}/>}
          iconPosition="left"
          onClick={handleCloseDialog}
          loader={false}
          disabled={false}
        />
        <AuthButton
          text="Save"
          icon={<BiSave size={24}/>}
          iconPosition="right"
          onClick={handleSave}
          loader={buttonLoading}
          disabled={getSaveBtnDisabled() || buttonLoading}
        />
      </div>
    </div>
  );
};

export default ChangePassword;
