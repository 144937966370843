import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { profileSetupApi } from "../API/ProfileSetup/profileSetupApi";
import { convertToUSANumber } from "../../utils";

export type UserDataType = {
  studentFirstName: string;
  studentLastName: string;
  studentUserName: string;
  studentPassword: string;
  studentPhoneNumber: string;
  parentFirstName: string;
  parentLastName: string;
  parentUserName: string;
  parentPassword: string;
  parentPhoneNumber: string;
  isParent: boolean;
  county: string;
  school: string;
  isExpired: boolean;
};

export type UserDataFormErrors = Record<keyof UserDataType, boolean>;

export type CurrentStepType =
  | "studentPersonalDetails"
  | "studentParentDetails"
  | "parentPersonalDetails"
  | "parentStudentDetails";

type ProfileSetupState = {
  selectedRole: "student" | "parent";
  currentStep: CurrentStepType;
  userData: UserDataType;
  countyData: any;
  schoolData: any;
  userDataErrors: UserDataFormErrors;
  emailVerifyId: string;
  studentName: string;
  parentName: string;
};

const initialState: ProfileSetupState = {
  selectedRole: "student",
  currentStep: "studentPersonalDetails",
  userData: {
    studentFirstName: "",
    studentLastName: "",
    studentUserName: "",
    studentPassword: "",
    studentPhoneNumber: "",
    parentFirstName: "",
    parentLastName: "",
    parentUserName: "",
    parentPassword: "",
    parentPhoneNumber: "",
    isParent: false,
    county: "",
    school: "",
    isExpired: false,
  },
  userDataErrors: {
    studentFirstName: false,
    studentLastName: false,
    studentUserName: false,
    studentPassword: false,
    studentPhoneNumber: false,
    parentFirstName: false,
    parentLastName: false,
    parentUserName: false,
    parentPassword: false,
    parentPhoneNumber: false,
    isParent: false,
    county: false,
    school: false,
    isExpired: false,
  },
  countyData: [],
  schoolData: [],
  emailVerifyId: "",
  studentName: "",
  parentName: "",
};

const profileSetupSlice = createSlice({
  name: "profileSetup",
  initialState,
  reducers: {
    setEmailVerifyId: (state, action) => {
      state.emailVerifyId = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setUserData: (state, action: PayloadAction<Partial<UserDataType>>) => {
      state.userData = {
        ...state.userData,
        ...action.payload,
      };
    },
    setUserDataErrors: (
      state,
      action: PayloadAction<Partial<UserDataFormErrors>>
    ) => {
      state.userDataErrors = {
        ...state.userDataErrors,
        ...action.payload,
      };
    },

    resetUserDataForm: (state) => {
      state.userData = initialState.userData;
      state.userDataErrors = initialState.userDataErrors;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      profileSetupApi.endpoints.countyGetAll.matchFulfilled,
      (state, action) => {
        const data = action.payload?.data;
        state.countyData = data;
      }
    );
    builder.addMatcher(
      profileSetupApi.endpoints.getCountySchoolById.matchFulfilled,
      (state, action) => {
        const data = action.payload?.data;
        state.schoolData = data;
      }
    );
    builder.addMatcher(
      profileSetupApi.endpoints.getUserDataById.matchFulfilled,
      (state, action) => {
        const data = action.payload?.data;
        const formattedNumber = convertToUSANumber(data?.mobile);
        if (data.isParent) {
          state.parentName = data.firstName;
          state.userData = {
            parentFirstName: data.firstName || "",
            parentLastName: data.lastName || "",
            parentUserName: data.email || "",
            parentPassword: "",
            parentPhoneNumber: formattedNumber || "",
            isParent: data.isParent || false,
            county: data.county || "",
            school: data.school || "",
            studentFirstName: "",
            studentLastName: "",
            studentUserName: "",
            studentPassword: "",
            studentPhoneNumber: "",
            isExpired: data.isExpired || false,
          };
        } else {
          state.studentName = data.firstName;
          state.userData = {
            studentFirstName: data.firstName || "",
            studentLastName: data.lastName || "",
            studentUserName: data.email || "",
            studentPassword: "",
            studentPhoneNumber: formattedNumber || "",
            isParent: data.isParent || false,
            county: data.county || "",
            school: data.school || "",
            parentFirstName: "",
            parentLastName: "",
            parentUserName: "",
            parentPassword: "",
            parentPhoneNumber: "",
            isExpired: data.isExpired || false,
          };
        }
      }
    );
  },
});

export const {
  setSelectedRole,
  setCurrentStep,
  resetUserDataForm,
  setUserData,
  setUserDataErrors,
  setEmailVerifyId,
} = profileSetupSlice.actions;
export default profileSetupSlice.reducer;
