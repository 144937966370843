import React, { useState } from "react";
import { RiArrowRightSLine, RiEyeCloseLine } from "react-icons/ri";
import AuthButton from "./LoginButton";
import { RiArrowLeftSLine } from "react-icons/ri";
import { MdOutlineCheck, MdOutlineRemoveRedEye } from "react-icons/md";
import {
  setCurrentStep,
  setUserDataErrors,
} from "../../redux/Slices/profileSetupSlice";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import { useEmailValidation, usePhoneValidation } from "../../utils";
import { getItem } from "../../utils/token";
import { BiSave } from "react-icons/bi";
import { closeDialog } from "../../redux/Slices/dialogSlice";

export type ParentDetailsFormType = {
  isParentRoute: boolean;
  handleFinishClick?: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handlePhoneChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setConfirmPassword: (val: string) => void;
  confirmPassword: string;
  setError: (val: string) => void;
  loading: boolean;
  isDashboard?: boolean;
  handleEmailExists: () => void;
};

const ParentDetailsForm = ({
  isParentRoute,
  handleFinishClick,
  handleChange,
  handlePhoneChange,
  setConfirmPassword,
  confirmPassword,
  setError,
  loading,
  isDashboard,
  handleEmailExists
}: ParentDetailsFormType) => {
  const dispatch = useDispatch();
  const { currentStep, userData, selectedRole, userDataErrors } = useSelector(
    (state: RootState) => state.root.profileSetupSlice
  );
  const {
    parentFirstName,
    parentLastName,
    parentUserName,
    parentPhoneNumber,
    parentPassword,
  } = userData;

  const { validateEmail } = useEmailValidation();
  const { validatePhone } = usePhoneValidation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleNextClick = () => {
    const newErrors = {
      parentFirstName: !parentFirstName,
      parentLastName: !parentLastName,
      parentUserName: selectedRole === "parent" ? false : !parentUserName,
    };
    dispatch(setUserDataErrors({ ...newErrors }));
    const isEmailValid = validateEmail(parentUserName);
    const isPhoneValid = validatePhone(parentPhoneNumber);

    if (!isEmailValid) {
      dispatch(setUserDataErrors({ parentUserName: true }));
    }

    if (!isPhoneValid) {
      dispatch(setUserDataErrors({ parentPhoneNumber: true }));
    }
    // Proceed if there are no errors
    if (
      !Object.values(newErrors).some(Boolean) &&
      isEmailValid &&
      isPhoneValid
    ) {
      dispatch(setCurrentStep("parentStudentDetails"));
    }
  };

  const handleActionButton = () => {
    const handleSubmit = {
      buttonText: isDashboard ? "Save" : "Finish",
      action: handleFinishClick,
    };

    const handleNext = {
      buttonText: "Next",
      action: handleNextClick,
    };

    return isParentRoute ||
      currentStep === "studentParentDetails" ||
      isDashboard
      ? handleSubmit
      : handleNext;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const getFinishIcon = () => {
    if (currentStep === "parentPersonalDetails") {
      return <RiArrowRightSLine />;
    } else if (isParentRoute) {
      return <MdOutlineCheck />;
    } else if (isDashboard) {
      return <BiSave size={24} />;
    }
  };

  const handleBack = () => {
    if (isDashboard) {
      dispatch(closeDialog());
    } else {
      dispatch(setCurrentStep("studentPersonalDetails"));
    }
  };

  return (
    <div className="w-[446px]">
      <CustomTextField
        onChangeHandler={handleChange}
        placeholder={isDashboard ? `Parent's First Name` : "First Name"}
        type={"text"}
        name="parentFirstName"
        mandatory={true}
        value={parentFirstName}
        className="w-full !border-[#646464]"
        isError={userDataErrors.parentFirstName}
      />
      <CustomTextField
        onChangeHandler={handleChange}
        placeholder={isDashboard ? `Parent's Last Name` : "Last Name"}
        type={"text"}
        name="parentLastName"
        mandatory={true}
        value={parentLastName}
        className="w-full !border-[#646464]"
        containerClass="mt-6"
        isError={userDataErrors.parentLastName}
      />

      <CustomTextField
        onChangeHandler={handleChange}
        placeholder="Email"
        type={"text"}
        name="parentUserName"
        mandatory={true}
        disabled={selectedRole === "parent" || isParentRoute}
        value={
          selectedRole === "parent"
            ? getItem("userEmail")
            : parentUserName?.trim() || ""
        }
        className="w-full !border-[#646464]"
        containerClass="mt-6"
        isError={userDataErrors.parentUserName}
        onBlur={()=>{isDashboard && handleEmailExists()}}
      />

      <CustomTextField
        onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
          handlePhoneChange(e)
        }
        placeholder="Phone Number"
        type={"text"}
        name="parentPhoneNumber"
        mandatory={true}
        value={parentPhoneNumber}
        className="w-full !border-[#646464]"
        containerClass="mt-6"
        isError={userDataErrors.parentPhoneNumber}
      />
      {isParentRoute && (
        <>
          <div className="flex justify-between relative mt-6">
            <CustomTextField
              onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleChange(e)
              }
              placeholder="Password"
              type={`${showPassword ? "text" : "password"}`}
              name="parentPassword"
              value={parentPassword?.trim()}
              className="w-full !border-[#646464]"
              isError={userDataErrors.parentPassword}
            />
            {parentPassword && (
              <button
                className={`${
                  userDataErrors.parentPassword && "text-[#D00000]"
                } absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]`}
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />}
              </button>
            )}
          </div>
          <div className="flex justify-between relative mt-6">
            <CustomTextField
              onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(e.target.value)
              }
              placeholder="Confirm Password"
              type={`${showConfirmPassword ? "text" : "password"}`}
              name="studentPassword"
              value={confirmPassword?.trim()}
              className="w-full !border-[#646464]"
              isError={!setError}
              onPaste={handlePaste}
            />
            {confirmPassword && (
              <button
                className="absolute right-6 bottom-2 cursor-pointer text-2xl text-[#646464]"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <MdOutlineRemoveRedEye />
                ) : (
                  <RiEyeCloseLine />
                )}
              </button>
            )}
          </div>
        </>
      )}
      <div
        className={`${
          isParentRoute ? "justify-center" : "justify-between"
        } mt-9 flex items-center`}
      >
        {!isParentRoute && (
          <AuthButton
            text="Back"
            icon={<RiArrowLeftSLine />}
            iconPosition="left"
            onClick={handleBack}
            loader={false}
            disabled={currentStep === "parentPersonalDetails"}
          />
        )}

        <AuthButton
          text={handleActionButton()?.buttonText}
          icon={getFinishIcon()}
          iconPosition="right"
          onClick={handleActionButton()?.action}
          loader={false}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default ParentDetailsForm;
