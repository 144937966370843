import { useEffect } from "react";
import { StudentCountyDetails, StudentDetail } from "../NewResults/ResultsPage";
import ResultsTab from "../NewResults/ResultPageTabs";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/Store/store";
import {
  useGetParentStudentInfoQuery,
  useLazyGetUserResultsQuery,
} from "../../redux/API/StartTest/startTestApi";
import { setParentStudentId } from "../../redux/Slices/startFullTestSlice";
import { getFullName } from "../../utils";
import { getItem, setItem } from "../../utils/token";

const ParentDashboard = () => {
  const dispatch = useDispatch();

  const { data: parentAssociatedData, isSuccess: parentAssociatedSuccess } = useGetParentStudentInfoQuery({});
  const [
    getUserResults,
    {
      data: studentTestData,
      isLoading: studentTestDataLoading,
    },
  ] = useLazyGetUserResultsQuery();

  const { parentStudentId } = useSelector(
    (state: RootState) => state.root.startFullTest
  );

  const handleStudentSelection = (id: string) => {
    dispatch(setParentStudentId(id));
    getUserResults(id);
    setItem('studentId',id)
  };

  const selectedStudent: StudentDetail =
    parentStudentId &&
    parentAssociatedData?.data?.length > 0 &&
    parentAssociatedData?.data?.find(
      (student: StudentDetail) => student.id === parentStudentId
    );

  useEffect(() => {
    if (parentAssociatedSuccess && parentAssociatedData) {
      let studentId =
        parentAssociatedData.data.length > 0 && parentAssociatedData.data[0].id;
      if (studentId) {
        dispatch(setParentStudentId(studentId));
        getUserResults(studentId);
        setItem("studentId", studentId);
      }
    }
  }, [parentAssociatedSuccess, parentAssociatedData]);
  const userRole = getItem("userRole");
  return (
    <>
      {userRole === "PARENT" ? (
        <div className="w-[70%] mx-auto flex flex-col gap-9 my-12 relative">
          {parentAssociatedData?.data?.length > 0 ? (
            <>
              {" "}
              <div className="absolute right-0 flex justify-between py-[18px] items-center">
                <StudentCountyDetails
                  studentName={getFullName(
                    selectedStudent?.firstName,
                    selectedStudent?.lastName
                  )}
                  studentDetailData={parentAssociatedData?.data}
                  handleStudentSelection={handleStudentSelection}
                />
              </div>
              <ResultsTab
                resultsData={studentTestData?.data}
                isLoading={studentTestDataLoading}
                studentId={parentStudentId}
              />
            </>
          ) : (
            <div className="flex items-center justify-center overflow-hidden flex-col mt-80">
              <p className="text-3xl text-lightBlack">
                The student has not accepted your invitation yet.
              </p>
            </div>
          )}
        </div>
      ) : (
        <div className="flex items-center justify-center overflow-hidden flex-col mt-80">
          <p className="text-3xl text-lightBlack">
            You are not authorized to access this page.
          </p>
        </div>
      )}
    </>
  );
};

export default ParentDashboard;
