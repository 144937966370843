import React, { useState } from "react";
import CustomTextField from "../../components/ReUsableComponents/CustomTextField";
import CustomButton from "../../components/ReUsableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  setCreateAccount,
  setEmailError,
  setMobileNumberError,
} from "../../redux/Slices/loginSlice";
import { useSignupMutation } from "../../redux/API/Login/loginApi";
import Loader from "../../components/ReUsableComponents/Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import showIcon from "../../assets/Images/eyeopen.svg";
import hideIcon from "../../assets/Images/eyeclose.svg";

function SignUp(props: any) {
  const emailPattern = /^[a-zA-Z0-9][^\s@]*@[^\s@]+\.[a-zA-Z]+$/;
  const navigate = useNavigate();
  const [userSignUpData, setUserSignUpData] = useState({
    userName: "",
    password: "",
    name: "",
    mobile: "",
    profilePic: "",
    whenExam: "",
    referalCode: "",
  });
  const [signup, { isLoading }] = useSignupMutation();
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const handleChangeSignUp = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserSignUpData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const { emailError, mobileNumberError } = useSelector(
    (state: any) => state.root.loginSlice
  );

  const validateEmail = (email: any) => {
    if (!emailPattern.test(email)) {
      return "Please Enter Valid Email Ex:abc@domain.com";
    }
    return "";
  };

  const validatePhoneNumber = (mobile: any) => {
    if (mobile && !isValidPhoneNumber("+" + mobile)) {
      return "Please Enter Valid Mobile Number";
    }
    return "";
  };

  const handleCreateAccount = async () => {
    try {
      const emailError = validateEmail(userSignUpData.userName);
      const mobileError = validatePhoneNumber(userSignUpData.mobile);

      if (emailError) {
        dispatch(setEmailError(emailError));
      } else if (mobileError) {
        dispatch(setMobileNumberError(mobileError));
      } else {
        dispatch(setEmailError(""));
        dispatch(setMobileNumberError(""));

        const formattedExam =
          userSignUpData.whenExam &&
          new Date(userSignUpData.whenExam).toISOString();
        const signUpResponse = await signup({
          ...userSignUpData,
          whenExam: formattedExam,
        }).unwrap();

        if (signUpResponse.message) {
          toast.success(signUpResponse.message);
          props.setIsAnimated(!props.isAnimated);
        }
      }
    } catch (signUpError: any) {
      if (signUpError.status === 409) {
        toast.error(signUpError.data.message);
      } else {
        toast.error("Unable to Sign Up");
      }
    }
  };
  const handleChangeMobileNumber = (value: string) => {
    setUserSignUpData({ ...userSignUpData, mobile: value });
  };
  return (
    <div className="flex flex-col lg:mt-0 md:-mt-[1rem] -mt-[1.5rem] lg:ml-0 md:ml-2 ml-2">
      <div className="flex flex-col gap-0">
        <div className="lg:-mb-[5px] md:-mb-[0.25rem] -mb-[0.5rem]">
          <CustomTextField
            label="Full Name"
            onChangeHandler={handleChangeSignUp}
            placeholder="Enter Your Full Name"
            type="text"
            value={userSignUpData.name}
            name="name"
            mandatory={true}
          />
        </div>
        <div className="lg:-mb-[5px] md:-mb-[0.25rem] -mb-[0.5rem]">
          <div className=" lg:text-sm md:text-[0.75rem] text-[0.75rem] mb-1 text-primaryColor mt-4">
            Mobile Number
          </div>
          <div className="w-[98%]">
            <PhoneInput
              containerClass="w-full"
              inputClass="border !border-[#E3E5E9] !rounded-lg p-2 focus:outline-none focus:border-black w-[381px] h-[41px]"
              country={"in"}
              value={userSignUpData.mobile}
              onChange={handleChangeMobileNumber}
            />
          </div>
          <div className="text-lg text-red-500 mt-2">{mobileNumberError}</div>
        </div>
        <div className="lg:-mb-[5px] md:-mb-[0.25rem] -mb-[0.5rem]">
          <CustomTextField
            label="When Exam"
            onChangeHandler={handleChangeSignUp}
            placeholder="Enter Your Exam Date"
            type="date"
            value={userSignUpData.whenExam}
            name="whenExam"
          />
        </div>
        <div className="lg:-mb-[5px] md:-mb-[0.75rem] -mb-[1.15rem]">
          <CustomTextField
            label="Referal Code"
            onChangeHandler={handleChangeSignUp}
            placeholder="Enter Your Referal Code "
            type="text"
            value={userSignUpData.referalCode}
            name="referalCode"
          />
        </div>
        <div className="lg:-mb-[5px] md:-mb-[0.75rem] -mb-[1.15rem]">
          <CustomTextField
            label="Email"
            onChangeHandler={handleChangeSignUp}
            placeholder="Enter Your Email"
            type="text"
            value={userSignUpData.userName}
            name="userName"
            mandatory={true}
          />
        </div>
        <div className="text-lg text-red-500 mt-2">{emailError}</div>
        <div className="lg:-mb-[5px] md:-mb-[0.25rem] -mb-[0.5rem]">
          <div className="w-full">
            <CustomTextField
              label="Password"
              onChangeHandler={handleChangeSignUp}
              placeholder="Enter Your Password"
              type={`${showPassword ? "text" : "password"}`}
              value={userSignUpData.password}
              name="password"
              mandatory={true}
            />
            {userSignUpData.password && (
              <button
                className="absolute right-28 bottom-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <img src={showIcon} alt="viewPass" />
                ) : (
                  <img src={hideIcon} alt="hidePass" />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
      <div className=" lg:mt-8 md:mt-[1rem] mt-[2rem] flex flex-col md:flex-row md:items-center md:justify-between w-[98%]">
        <CustomButton
          width="w-full"
          name="Create Account"
          onClickHandler={handleCreateAccount}
          disabled={
            isLoading ||
            userSignUpData.userName === "" ||
            userSignUpData.password === "" ||
            userSignUpData.name === ""
          }
        />
        {isLoading && <Loader />}
      </div>
      <h3
        className="text-[#202020]lg:text-xl md:text-lg text-[1rem] flex justify-center md:mt-[0.5rem] mt-6 mb-[1.5rem]
       "
      >
        <span>Already have an account?</span>
        <button
          className="ml-2 text-primaryColor font-bold underline cursor-pointer"
          onClick={() => {
            dispatch(setCreateAccount(false));
            dispatch(setEmailError(""));
            dispatch(setMobileNumberError(""));
            props.setIsAnimated(!props.isAnimated);
            navigate("/login");
          }}
        >
          Log in
        </button>
      </h3>
    </div>
  );
}

export default SignUp;
