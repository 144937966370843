import CustomButton from "../components/ReUsableComponents/CustomButton";

export const next = (nexxtClickHandler:any) => {
    return (
      <div>
        <CustomButton
          name="Next"
          onClickHandler={nexxtClickHandler}
          font="font-bold"
          py="py-2 px-5"
          round="full"
          text="text-sm"
        />
      </div>
    );
  };
  
export const generateUniqueKey = () => {
  return Math.random().toString(36).slice(2, 11); // Using slice() instead of substr()
}